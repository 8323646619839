<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
   
      <PrivilegeSet :detail="privilegeSet" />
      <div class="content-set-privilege mt-3">
        <PrivilegeSetList
          :idPrivilege="privilegeSet.id"
          :list="privilegeSet.tag_coupon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PrivilegeSet from "@/components/privilege/set/PrivilegeSet";
import PrivilegeSetList from "@/components/privilege/set/PrivilegeSetList";
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
export default {
  components: {
    OtherLoading,
    PrivilegeSet,
    PrivilegeSetList,
    Breadcrumb
  },
  data() {
    return {
      id: this.$route.params.id,
      privilegeSet: {},
      isLoading: true,
      breadcrumbList: [
        {
          text: "สิทธิพิเศษ",
          to: "/privilege"
        }
      ]
    };
  },
  async created() {
    await this.getPrivilegeSet();
  },
  methods: {
    async getPrivilegeSet() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Privilege/privilege_coupon_list/${this.id}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.isLoading = false;
            this.privilegeSet = data.detail;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
