<template>
  <div class="content-privilege">
    <div v-for="(item, index) in list" :key="index">
      <div class="px-3 pt-2 mx-1">
        <b-row class="content-privilege-group1 shadow">
          <b-col cols="5" class="p-0">
            <b-row class="ft-12 my-1" v-if="item.is_previlege_coupon">
              <b-col cols="2" class="p-0 text-center privilege-gift"
                ><font-awesome-icon icon="gift"
              /></b-col>
              <b-col cols="8" class="p-0">
                <b-button
                  class="badge badge-secondary"
                  @click.prevent="
                    $router.push(`/privilege/set/${item.privilege_id}`)
                  "
                  >{{ item.privilege_name }}</b-button
                >
              </b-col>
            </b-row>
            <div>
              <b-img-lazy
                v-if="item.image_url"
                v-bind="mainProps"
                :src="item.image_url"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
              <b-img-lazy
                v-else
                v-bind="mainProps"
                :src="require('@/assets/images/default-image.png')"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="title line-clamp-2">{{ item.name }}</div>
            <div class="description line-clamp-2">{{ item.detail }}</div>
            <div class="my-2 redeem-privilege">
              วันหมดอายุ: {{ item.validTo ? dateFormat(item.validTo) : "-" }}
            </div>
          </b-col>
          <b-col
            class="text-right arrow-content"
            @click="
              $router.push(`/couponhistory/${item.id}?page=${idPrivilege}`)
            "
          >
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true,
      type: Array
    },
    idPrivilege: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 300,
        height: 300,
        class: ""
      }
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    chkExpiredDate(date) {
      const currentDate = new Date();
      const privilegeDate = new Date(date);
      const sameDate = currentDate.getTime() > privilegeDate.getTime();
      return sameDate;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-privilege-group {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px 0;
}
.ft-12 {
  font-size: var(--text-sm);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.list-pri-content {
  background-color: #fff;
  margin: 1rem;
  border-radius: 5px;
  padding: 10px 0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
}
.description {
  font-size: var(--text-md);
  color: #4b4b4b;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.expired-date {
  font-size: var(--text-sm);
}
.see-all {
  font-size: var(--text-md);
  font-weight: 600;
  text-decoration: underline;
}
.radius-bd {
  border-radius: 5px;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.redeem-privilege {
  color: #979797;
  font-size: var(--text-sm);
}
.arrow-content {
  position: absolute;
  z-index: 1;
  right: 5px;
  color: #a69e9d;
  margin-top: 50px;
}
</style>
