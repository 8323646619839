<template>
  <div>
    <div class="text-center m-2">
      <div class="title">{{ detail.name }}</div>
      <div class="my-2">
        <img v-if="detail.image_url" :src="detail.image_url" class="w-img" />
        <img
          v-else
          :src="require('@/assets/images/default-image.png')"
          class="w-img"
        />
      </div>
      <div class="title1">{{ detail.name }}</div>
      <div class="description">{{ detail.short_description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      required: true,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #fff;
  font-weight: 600;
  font-size: var(--text-xl);
}
.title1 {
  font-size: var(--text-lg);
  color: #fff;
  font-weight: 600;
}
.description {
  color: gray;
  font-size: var(--text-md);
}
.w-img {
  width: 50%;
}
</style>
